$(document).ready(function() {
	if(location.hash == '#ad') {
		$('html, body').animate({
			scrollTop: $("#a-sad").offset().top
		}, 1);
	}
	$('.popup-gallery').magnificPopup({
		closeMarkup: '<span class="mfp-close icon-cancel close-popup"><svg version="1.1" width="14px" height="14px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 212.982 212.982" style="enable-background:new 0 0 212.982 212.982;" xml:space="preserve"><g><path style="fill-rule:evenodd;clip-rule:evenodd;" d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"/></g></svg></span>',
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			markup: '<div class="mfp-figure">'+
            '<span class="mfp-close icon-cancel close-popup"><svg version="1.1" width="14px" height="14px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 212.982 212.982" style="enable-background:new 0 0 212.982 212.982;" xml:space="preserve"><g><path style="fill-rule:evenodd;clip-rule:evenodd;" d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"/></g></svg></span>'+
            '<div class="mfp-img"></div>'+
          '</div>',
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			// titleSrc: function(item) {
			// 	return item.el.attr('title');
			// }
		}
    });
    

    if($('.section-game').children().first().hasClass('game-slide')) {
        // adds ids to pages to slide up
        var itemsToSlideUp = $('.game-slide').length;
        var itemsSlideDecrement = itemsToSlideUp;

        for(var i=0; i<itemsToSlideUp; i++) {
            $('.game-slide').eq(i).attr('id', 'js-slide-left-' + (itemsSlideDecrement));
            $('#js-slide-left-' + (itemsSlideDecrement)).css('z-index',20 + itemsSlideDecrement);
            itemsSlideDecrement--;
        }
	}
	
	//temperature slider
	var slider = $("#ideal-temp");
	var output = $("#cur-temp");
	output.html(slider.val() + '.0°C'); // Display the default slider value

	// Update the current slider value (each time you drag the slider handle)
	$(slider).on('input', function (e) {
		e.preventDefault();
		
		var c = '°C';
		if (parseFloat(slider.val())- Math.floor(slider.val()) == 0) {
			console.log(parseFloat(slider.val())- Math.floor(slider.val()) == 0);
			// console.log( parseFloat(slider.val()) - Math.floor(slider.val() == 0) );
			c = '.0°C'
		}
		output.html(slider.val() + c);
	});
});

$(".js-scroll-to-step2").click(function() {
	$([document.documentElement, document.body]).animate({
		scrollTop: $(".section-green").offset().top + 60
	}, 2000);
});

$(".js-scroll-to-step3").click(function() {
	$([document.documentElement, document.body]).animate({
		scrollTop: $(".section-prizes").offset().top + 60
	}, 1500);
});

$(".js-run-game").click(function() {
	$(".section-game").slideDown(500);
	$([document.documentElement, document.body]).animate({
		scrollTop: $(".section-game").offset().top}, 1500);
});

// $('.app-to-step-2').click(function() {
//     $('.js-slide-1').addClass('slide-out');
// });


$(document).ready(function(){
  var owl = $('.owl-carousel');

  owl.owlCarousel({
    items: 1,
    autoHeight: true,
    touchDrag: false,
    pullDrag: false,
    mouseDrag: false,
    freeDrag: false
  });

  $('.app-to-step-2').click(function() {
    owl.trigger('next.owl.carousel');
  });

  $('.section-game').on('click', '.button', function() {
    owl.trigger('refresh.owl.carousel');
  });

  $( ".owl-stage .owl-item:nth-child(2)" ).css('background', '#007bff');
});


$('.app-to-step-3').click(function() {	
	 if ($("#short-name").val().length >= 2  && $("#short-name").val().length <= 20) { 
		var error = $("#name-error");
		// validation
		var name = $("#short-name").val();
		var temp = $("#ideal-temp").val();
		 if (!name || !temp) {
			error.empty();
			error.append('<p>Došlo je do pogreške, pokušaj ponovo</p>');
			return;
		} 
		$(".js-text-name").text(name);
		$(".js-text-temp").text(temp + '°C');
		$('.owl-carousel').trigger('next.owl.carousel');
		
		// $('.js-slide-2').addClass('slide-out');

		// var data = new FormData();
		// data.append('photo', $('.photo')[0].files[0]);
		// data.append('name', name);
		// data.append('temp', temp);

		// var homeUrl = window.location.protocol + "//" + window.location.host + "/";
		// $.ajax({
		//     url: '/photos/upload/',
		//     type: 'POST',
		//     data: data,
  //       	contentType: false,
  //       	processData: false,
		//     success: function( response ) {
		//     	response = JSON.parse(response);
		//         if (response.status == 'success') {
		//         	$("#photo-preview").attr("src", homeUrl+response.path);
		//         	$("#photo-preview").data('relative-url', response.path);
		//         	$("#photo-preview").width(768).height(768);
		//         	$('.owl-carousel').trigger('next.owl.carousel');
		//         	// $('.js-slide-2').addClass('slide-out');
		//         } else {
		//         	error.empty();
		// 			error.append('<p>'+ response.message +'</p>');
		//         }
		//     },
		//     error: function (xhr, status, error) {
		//         console.log( xhr.responseText );
		//     }
		// });
		
	}  else {
		$(".form-message").empty();
		if (!$("#short-name").val()) {
			$("#name-error").append('<p>Molimo unesi ime</p>');
		} else if($("#short-name").val().length < 2){
			$("#name-error").append('<p>Ime je prekratko</p>');
		} else if($("#short-name").val().length >= 20) {
			$("#name-error").append('<p>Ime je predugo</p>');
		}
	} 
});

function animateSmileCounter (percentage) {
	var percentToAnimate =  Math.round(percentage);
	var animateProgress = $('.animate-progress');
	var animateProgressParams = $('.animate-progress-params');

	animateProgress.addClass('animate-progress-params');
	calculated = 275 / 100 * percentToAnimate;
	
	animateProgress.css('stroke-dasharray', calculated + ' ' + 276);
	animateProgress.css('stroke-dashoffset', calculated);
	animateProgressParams.css('stroke-dashoffset', calculated);

	$('.number-to-animate').prop('Counter',0).animate({
		Counter: $('.number-to-animate').text()
	}, {
		duration: 4000,
		easing: 'swing',
		step: function (now) {
			$('.number-to-animate').text(Math.round(now));
		}
	});
}
 // $('.app-to-step-4').click(function() {
	//  $('.js-slide-3').addClass('slide-out');
	 
	//  animateSmileCounter();

	//  setTimeout(function() {
	// 	 $('.smile-measure-btns').fadeIn();
	// 	 $('.loader-text').text('Tvoj osmijeh je na:');

	//  }, 4500);

// 	var percentToAnimate = parseInt($(".number-to-animate").text());
// 	var animateProgress = $('.animate-progress');
// 	var animateProgressParams = $('.animate-progress-params');

// 	animateProgress.addClass('animate-progress-params');
// 	calculated = 275 / 100 * percentToAnimate;
	
// 	animateProgress.css('stroke-dasharray', calculated + ' ' + 276);
// 	animateProgress.css('stroke-dashoffset', calculated);
// 	animateProgressParams.css('stroke-dashoffset', calculated);

// 	$('.number-to-animate').prop('Counter',0).animate({
// 		Counter: $('.number-to-animate').text()
// 	}, {
// 		duration: 4000,
// 		easing: 'swing',
// 		step: function (now) {
// 			$('.number-to-animate').text(Math.ceil(now));
// 		}
// 	});

 // });

$('.app-to-step-5').click(function() {
    //$('.js-slide-4').addClass('slide-out');
    $('.owl-carousel').trigger('next.owl.carousel');
});

$('.more-options-on-md').click(function() {
	$('.thankyou-title').fadeOut();
	$('.last-ste-fadout').fadeOut();
	$('.more-options-on-md').fadeOut();
	$('.fb-box').fadeIn();
});


$('.fb-deny').on('click', function(event) {
	event.preventDefault();

	$([document.documentElement, document.body]).animate({
		scrollTop: $(".section-gallery").offset().top + 60
	}, 2000);
});

var imagePath;
// $('.app-to-step-6').click(function() {
//     $('.js-slide-5').addClass('slide-out');
// });
$(document).on('change', '.photo', function(e) {
	$('.rotate-sun.image-loader').removeClass('d-none');
	$('.app-to-step-4 span').addClass('d-none');
	// $("#btnSubmit").attr("disabled", true);
	var error = $("#name-error");
	// validation
	var name = $("#short-name").val();
	var temp = $("#ideal-temp").val();
	if (!name || !temp) {
		error.empty();
		error.append('<p>Došlo je do pogreške, pokušajte ponovo</p>');
		return;
	}
	console.log('change');
	var data = new FormData();
		data.append('photo', $(this)[0].files[0]);
		data.append('name', name);
		data.append('temp', temp);
	
		var picture = $(this)[0].files[0];
	 	EXIF.getData(picture, function() {
	        var allMetaData = EXIF.getAllTags(this);
	       	var orientation = allMetaData.Orientation;
	       	data.append('orientation', orientation);
			var homeUrl = window.location.protocol + "//" + window.location.host + "/";
			$.ajax({
			    url: '/photos/upload/',
			    type: 'POST',
			    data: data,
	        	contentType: false,
	        	processData: false,
			    success: function( response ) {
			    	console.log(response);
			    	response = JSON.parse(response);
			        if (response.status == 'success') {
						$('.rotate-sun.image-loader').addClass('d-none');
						$('.app-to-step-4 span').removeClass('d-none');
			        	$("#photo-preview").removeAttr("style")
			        	$("#photo-preview").attr("style", "background-image:url('"+homeUrl+response.path+"')");
			        	$("#photo-final").attr('src', homeUrl+response.path);
			        	$("#photo-preview").data('relative-url', response.path);
			        	$("#photo-preview").width(768).height(768);
			        	$(".number-to-animate").text(response.smilePercent);
	                    $(".final-percentage").text(Math.round(response.smilePercent));
						var tempImagePath = response.path;
						imagePath = homeUrl + 'view' + tempImagePath.split(".")[0];
			        	$('.owl-carousel').trigger('next.owl.carousel');
			        	//$('.js-slide-3').addClass('slide-out');
			        	animateSmileCounter(response.smilePercent);

						 setTimeout(function() {
							 $('.smile-measure-btns').css('display', 'flex');
							 $('.loader-text').text('Tvoj osmijeh je na:');

						 }, 4500);
			        	// animateSmileCounter();
			        } else {
			        	error.empty();
						error.append('<p>'+ response.message +'</p>');
			        }
			    },
			    error: function (xhr, status, error) {
			        console.log( xhr.responseText );
			    }
			});
	    });

});

$("#go-foward").on('click', function (e) {
	e.preventDefault();
	$('.js-slide-4').addClass('slide-out');
});

$('#toggle-sidebar').on('click', function() {
	$('.nav-list-items').toggleClass('increase-nav-height');
});

$('#toggle-sidebar').on('click', function() {
	console.log('okida');
	/* $('.nav-list-items').toggle();
	$('.nav-list-items').slideToggle(300); */
	$('.hamburger span').toggleClass('animate-hamburger');
	$('.hamburger span').toggleClass('w-0');
});

$('.cookie-consent-agree').on('click', function(event) {
	event.preventDefault();

	setCookie('cookie-consent', true, 30);
	$('.cookie-consent').hide();
});

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}


$(window).scroll(function() {

	var elem = $('.abs-img-green');
	var docViewTop = $(window).scrollTop();
	var docViewBottom = docViewTop + $(window).height();

	var elemTop = $(elem).offset().top - $(window).height();
	var elemBottom = elemTop + $(elem).height();
	var elemCenter = elemTop + ($(elem).height() / 2);

	if($(window).width() > 1024) {
		if(docViewBottom > elemCenter) {
			elem.addClass('grow-dog');
		}

		if($(window).width() >= 1200) {
			if(docViewTop > 1000) {
				$('.section-intro .container-fluid').css('position', 'absolute');
			} else {
				$('.section-intro .container-fluid').css('position', 'fixed');
			}
		}
	}
});

function startWinner() {
	$('.draw-step-1').fadeOut(600);

	setTimeout(function() {
		$('.draw-step-2').css("display", "flex").hide().fadeIn(600);
		$('.drawing-progress-animated').css('width','100%');
		$('.skew-bg-1').css('background-image','radial-gradient(circle at 9% 0, #9d62c8, #ba7ddd)');
		$('.drawing-bg-general').css('background-image','linear-gradient(to right, #f5b24b, #e69703)');
	}, 600);


	setTimeout(function() {
		$('.draw-step-2').fadeOut(600);

		setTimeout(function() {
			$('.skew-bg-1').css('background-image','none');
			$('.drawing-bg-general').css({'background':'linear-gradient(0deg, rgba(244, 199, 0, 0.8), rgba(229, 149, 0, 0.8)), url(dist/images/drawing-smilies.png) center center / cover no-repeat', 'background-image':'linear-gradient(to right, rgb(244, 199, 0), rgb(229, 149, 0));'});
			$('.draw-step-3').css("display", "flex").hide().fadeIn(600);
			$.confetti.restart();
		}, 600);
		
	}, 4600);

}

function backToDrawStart() {
	$('.draw-step-3').fadeOut(600);

	setTimeout(function() {
		$('.draw-step-1').fadeIn(600);
		$('.skew-bg-1').css('background-image','radial-gradient(circle at 9% 0, #009ad9, #0978a6)');
		$('.drawing-bg-general').css('background-image','linear-gradient(to right, #9fc162, #8dae65)');
		$('.drawing-progress-animated').css('width','0');
		$.confetti.stop();
	}, 600);
	
}

$(document).keyup(function(e) {
	if (e.key === "Escape") {
		backToDrawStart();
	}
});

$('.js-to-draw-step-2').on('click', function() {
	startWinner();
});


$(document).keydown(function(e) {
	if (e.keyCode == '32') {
		startWinner();
	}
});