$(document).on('click', '#participate', function(e) {
	e.preventDefault();
	
	$.ajax({
	    url: '/count/returned',
	    type: 'POST',
	    success: function( response ) {
	        // response = JSON.parse( response );
	        console.log(typeof response);
	        console.log(response);
	    },
	    error: function (xhr, status, error) {
	        console.log( xhr.responseText );
	    },
	    complete: function () {
	    	// redirect to homepage
	    	window.location.replace(window.location.protocol + "//" + window.location.host + "/");
	    }
	});
});